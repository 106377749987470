import { makeRequest } from '~/lib/api/apiHelpers'

export async function postSubmitInviteRequest({
    email,
    name,
    lastName,
    phone,
    attribution,
}: {
    name: string
    email: string
    phone: number
    lastName: string
    attribution?: string
}) {
    return await makeRequest({
        path: '/web-invite-request',
        method: 'POST',
        body: {
            name,
            email,
            phone,
            lastName,
            ...(attribution && attribution !== '' ? { attribution } : {}),
        },
    })
}
