<template>
    <div
        class="mb-12 flex max-w-[654px] flex-col items-center gap-8 text-neutral-950"
    >
        <h2
            class="text-center font-850 text-[32px] leading-[35.55px] tracking-[-0.04em] md:leading-[54px] lg:text-[56px]"
        >
            People who love travel love {{ config.public.appName }}
        </h2>
    </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig()
</script>
