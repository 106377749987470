<template>
    <form
        novalidate
        class="my-12 flex w-full flex-col gap-4"
        @submit.prevent="handleSubmit"
    >
        <h1
            class="font-850 text-4xl leading-[40px] tracking-[-0.04em] md:block md:leading-[40px]"
        >
            Request Invite
        </h1>
        <p class="mb-3 max-w-screen-sm text-neutral-950">
            Share your details below, and we’ll reach out on WhatsApp to confirm
            your invite once a spot opens up.
        </p>

        <AInput
            v-model="form.name"
            placeholder="Enter your first name"
            label="First name"
            wrapper-class="bg-neutral-50"
        />
        <AInput
            v-model="form.lastName"
            placeholder="Enter your last name"
            label="Last name"
            wrapper-class="bg-neutral-50"
        />
        <AInput
            v-model="form.email"
            placeholder="Enter your email"
            label="Email"
            wrapper-class="bg-neutral-50"
        />
        <AInput
            id="phone-input"
            ref="phoneInput"
            v-model="phoneNumberWithoutCountryCode"
            wrapper-class="bg-neutral-50"
            :label-icon="IconWhatsapp"
            placeholder="Enter your WhatsApp number"
            label="WhatsApp number"
            :left-component="AuthenticationCountryCodeDropdown"
            :left-component-props="{
                'model-value': countryCode,
                'onUpdate:model-value': (val: any) => {
                    countryCode = val
                },
            }"
            autofocus
            class="border-none bg-transparent pl-0"
        />
        <AInput
            v-model="form.referralCode"
            placeholder="Enter your referral code if you have one"
            label="Referral code (optional)"
            wrapper-class="bg-neutral-50"
        />
        <AButton :loading="isPending" type="submit" button-class="!h-16 mt-6">
            Continue
        </AButton>
        <div class="flex flex-row items-center gap-2 text-center">
            <div>
                By clicking on continue, you agree with Ascend's
                <NuxtLink
                    :class="['underline']"
                    :href="Urls.Privacy()"
                    target="_blank"
                >
                    Privacy Policy
                </NuxtLink>
                and
                <NuxtLink
                    :class="['underline']"
                    :href="Urls.Terms()"
                    target="_blank"
                >
                    Terms of Service
                </NuxtLink>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue-sonner'

import { useRuntimeConfig } from '#imports'
import AuthenticationCountryCodeDropdown from '~/components/Authentication/AuthenticationCountryCodeDropdown.vue'
import IconWhatsapp from '~/components/Icon/IconWhatsapp.vue'
import { postSubmitInviteRequest } from '~/lib/api/mutations/postSubmitInviteRequest'

import {
    ICountryCode,
    initialCountryCode,
} from './Authentication/authenticationProvider'

const config = useRuntimeConfig()

const { mutate: mutateSubmitForm, isPending } = useMutation({
    mutationFn: postSubmitInviteRequest,
    onSuccess: () => {
        toast.success('Invite Request Submitted!')
        push(Urls.BizSuccess())
    },
    meta: {
        errorMessage: 'Failed to Submit Invite Request',
    },
})
const { push } = useRouter()
const phoneNumberWithoutCountryCode = ref('')
const countryCode = ref<ICountryCode>(initialCountryCode)
const form = ref({
    access_key: config.public.webFormsKey,
    subject: 'New Ascend for BizV2 Sign Up',
    name: '',
    email: '',
    lastName: '',
    referralCode: '',
})

// Email and phone validation regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^[0-9]+$/ // ensures only digits

const handleSubmit = (e: Event) => {
    e.preventDefault()

    if (!form.value.name) {
        toast.error('Please fill out the name')
        return
    }

    if (!form.value.lastName) {
        toast.error('Please fill out the last name')
        return
    }

    if (!form.value.email || !emailRegex.test(form.value.email)) {
        toast.error('Please provide a valid email address')
        return
    }

    if (!phoneNumberWithoutCountryCode.value) {
        toast.error('Please fill out the phone field')
        return
    }

    // Check if phone number is numeric and has a reasonable length
    if (!phoneRegex.test(phoneNumberWithoutCountryCode.value)) {
        toast.error('Phone number should only contain digits')
        return
    }

    if (
        phoneNumberWithoutCountryCode.value.length < 7 ||
        phoneNumberWithoutCountryCode.value.length > 15
    ) {
        toast.error('Phone number length must be between 7 and 15 digits')
        return
    }

    submitForm()
}

onMounted(() => {
    form.value.access_key = config.public.webFormsKey
})

const submitForm = async () => {
    try {
        // Combine the country code with the phone number without the "+" symbol
        const fullPhoneNumber =
            `${countryCode.value.dial_code}${phoneNumberWithoutCountryCode.value}`.replace(
                '+',
                ''
            )

        mutateSubmitForm({
            name: form.value.name,
            email: form.value.email,
            phone: Number.parseInt(fullPhoneNumber),
            attribution: form.value.referralCode,
            lastName: form.value.lastName,
        })
    } catch (error) {
        toast.error('Unable to submit data!')
    }
}
</script>
